import React, { useState } from 'react';
import { Checkbox, Grid, Typography, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import ResidentialImg from '../../../../assets/Group 192 (2).svg';
import CommercialImg from '../../../../assets/Group 190.svg';
import HotelImg from '../../../../assets/Group 189 (1).svg';
import RetailImg from '../../../../assets/Group 191 (1).svg';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { StyledButtonVilla } from '../../../common/togglebutton/StyledButtonVilla';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3vh',
  },
  button2: {
    minHeight: '100px',
    '&:focus': {
      outline: '2px solid' + theme.palette.primary.dark,
    },
  },
  button3: {
    paddingLeft: '8%',
    paddingRight: '8%',
    minHeight: '100px',
    '&:focus': {
      outline: '2px solid' + theme.palette.primary.dark,
    },
  },
  button4: {
    paddingLeft: '8%',
    paddingRight: '8%',
    minHeight: '100px',
    '&:focus': {
      outline: '2px solid' + theme.palette.primary.dark,
    },
  },
  image1: {
    paddingTop: '6%',
  },
  image4: {
    paddingTop: '15%',
  },
  text1: {
    paddingTop: '6%',
  },
  text2: {
    paddingTop: '4%',
  },
  text3: {
    paddingTop: '10%',
  },
  text4: {
    paddingTop: '6%',
  },
  selected: {
    outline: '2px solid' + theme.palette.primary.dark,
    backgroundColor: theme.palette.common.white,
  },
}));

function TooglebuttonGroupForCreateProject(props) {
  const {
    category,
    handleChange,
    clickedContinue,
    subCategory,
    setSubCategory,
    setChange = () => {},
    change,
    requiredpips,
    appendChanged,
    pipValues,
    disableSubcategorySelection = false,
  } = props;

  const classes = useStyles();
  const [checkboxDefaultChecked, setCheckboxDefaultChecked] = useState(false);

  const StyledButton = withStyles((theme) => ({
    root: {
      border: disableSubcategorySelection ? '1px solid' : '1px solid' + theme.palette.common.black,
      borderRadius: '40px',
      minWidth: '140px',
      '&:focus': {
        outline: 'none',
      },
      color: theme.palette.common.black,
    },
    selected: {
      border: '1px solid ' + theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark + '!important',
      color: theme.palette.common.white + '!important',
    },
  }))(ToggleButton);

  return (
    <>
      <Grid container item xs={6} className={classes.spacing}>
        <Typography variant="h5">Project Category*</Typography>
        {!disableSubcategorySelection && (
          <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 13 }}>
            Please choose your project type carefully. Once saved, it cannot be changed.
          </Typography>
        )}
      </Grid>
      <Grid container item xs={8} className={classes.spacing}>
        <>
          <ToggleButtonGroup
            size="large"
            value={category}
            // value="residential"
            exclusive
            onChange={handleChange}
          >
            <ToggleButton
              value="residential"
              classes={{ root: classes.button2, selected: classes.selected }}
              style={clickedContinue === true && !category ? { border: '1px solid red' } : null}
            >
              <Grid container direction="column" justify="center" alignItems="center" spacing={0}>
                <Grid item>
                  <img src={ResidentialImg} alt="" className={classes.image1} />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textPrimary" className={classes.text1}>
                    Residential
                  </Typography>
                </Grid>
              </Grid>
            </ToggleButton>
            <ToggleButton value="commercial" classes={{ root: classes.button2, selected: classes.selected }} disabled style={{ opacity: '0.5' }}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={0}>
                <Grid item>
                  <img src={CommercialImg} alt="" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textPrimary" className={classes.text2}>
                    Commercial
                  </Typography>
                </Grid>
              </Grid>
            </ToggleButton>
            <ToggleButton value="hotel" classes={{ root: classes.button3, selected: classes.selected }} disabled style={{ opacity: '0.5' }}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={0}>
                <Grid item>
                  <img src={HotelImg} alt="" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textPrimary" className={classes.text3}>
                    Hotel
                  </Typography>
                </Grid>
              </Grid>
            </ToggleButton>
            <ToggleButton value="retail" classes={{ root: classes.button4, selected: classes.selected }} disabled style={{ opacity: '0.5' }}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={0}>
                <Grid item>
                  <img src={RetailImg} alt="" className={classes.image4} />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textPrimary" className={classes.text4}>
                    Retail
                  </Typography>
                </Grid>
              </Grid>
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      </Grid>
      <Grid item xs={12}>
        {clickedContinue && clickedContinue === true && !category ? <FormHelperText style={{ color: 'red' }}>select a category.</FormHelperText> : ''}
      </Grid>
      <Grid container item xs={8} className={classes.spacing}>
        {category === 'residential' && (
          <>
            <Grid container direction="column" justify="center" alignItems="center" item xs={1} spacing={2}>
              <Grid item>
                <StyledButton
                  // style={{ outline: '0px solid' }}
                  disabled={disableSubcategorySelection}
                  value="villa"
                  selected={subCategory && subCategory === 'villa'}
                  onChange={() => {
                    setSubCategory('villa');
                    setChange(!change);
                  }}
                >
                  <Typography style={{ textTransform: 'capitalize' }}>Villa</Typography>
                </StyledButton>
              </Grid>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  or
                </Typography>
              </Grid>
              <Grid item>
                <StyledButton
                  disabled={disableSubcategorySelection}
                  value="apartment"
                  selected={subCategory && subCategory === 'apartment'}
                  onChange={() => {
                    setSubCategory('apartment');
                    setChange(!change);
                  }}
                >
                  <Typography style={{ textTransform: 'capitalize' }}>Apartment</Typography>
                </StyledButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {clickedContinue === true && !subCategory ? <FormHelperText style={{ color: 'red' }}>select a subcategory.</FormHelperText> : ''}
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item xs={8} spacing={1} className={classes.spacing} direction="row">
        {requiredpips &&
          requiredpips.data.map((field, index) => {
            if (field && field.name === 'project__pi_includeClubhouse') {
              return (
                <>
                  <Grid item={2} style={{ marginTop: '-1%' }}>
                    <Checkbox
                      checked={pipValues[field?._id] ?? false}
                      style={{ color: '#66E3BE' }}
                      onChange={(e) => {
                        appendChanged(field._id, e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid item={8} key={index}>
                    <Typography variant="h6" style={{ color: '#666666', lineHeight: '22px', letterSpacing: '0.571429px', marginTop: '1%' }}>
                      {field.displayName}
                    </Typography>
                  </Grid>
                </>
              );
            }
          })}
      </Grid>
    </>
  );
}

export default TooglebuttonGroupForCreateProject;
