import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PrimaryButton from '../button/Button';

const HeadingAndButton = (props) => {
  return (
    <>
      <Grid container alignItems="center" item xs={12} className={props.className}>
        <Grid item xs={2} align="left">
          <Typography variant="h3" color="textPrimary" gutterBottom>
            {props.heading}
          </Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs={2} data-tour="report">
          {/* <PrimaryButton children="create report" onClick={props.handleCreateReport} /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default HeadingAndButton;
