import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelectInterventions from '../../insights/child-components/CustomSelectInterventions';
import { useHistory } from 'react-router-dom';

// import lightManagementSystem from '../../../../../assets/lightManagementSystem.svg';
// import toxicNew from '../../../../../assets/icons/waste/segregation.svg';
import solarPV from '../../../../../assets/solarPV.svg';
import EfficientFixtures from '../../../../../assets/efficientFixtures.svg';
// import rooftopRunoff from '../../../../../assets/rooftopRunoff.svg';
// import surfaceRunoff from '../../../../../assets/surfaceRunoff.svg';
// import solarwaterheater from '../../../../../assets/solarwaterheater.svg';
import smartWaterMeter from '../../../../../assets/smartwatermeter.svg';
// import medicalWaste from '../../../../../assets/medical.png';
// import eWastehandling from '../../../../../assets/eWastehandling.svg';
import dualflush from '../../../../../assets/dualflush.svg';
// import CentralisedRO from '../../../../../assets/CentralisedRO.svg';
// import blackwaterReuse from '../../../../../assets/blackwaterReuse.svg';
import biogasPlant from '../../../../../assets/icons/waste/biogas.svg';
import greywaterReuse from '../../../../../assets/greywater.svg';
// import incinaretor from '../../../../../assets/icons/waste/snitary.svg';
// import composter from '../../../../../assets/composter.svg';
import EnergyCustom from '../../../../../assets/icons/energy/custom-icon.png';
import WaterCustom from '../../../../../assets/icons/water/custom-icon.png';
import WasteCustom from '../../../../../assets/icons/waste/custom-icon.png';
import MaterialsCustom from '../../../../../assets/interventions/materials/custom.svg';
import ExteriorPaints from '../../../../../assets/interventions/materials/exterior-paints.svg';
import { Link } from 'react-router-dom';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useParams } from 'react-router';
import PrimaryButton from '../../../../../components/common/button/Button';
import { InterventionCategoryContext } from '../../../../../projectRouting/Index';
// import concrete from '../../../../../assets/interventions/materials/concrete.svg';
// import structure from '../../../../../assets/interventions/materials/structure.svg';
// import balconyandutility from '../../../../../assets/interventions/materials/balconyandutility.svg';
// import livinganddining from '../../../../../assets/interventions/materials/livinganddining.svg';
// import bedroom from '../../../../../assets/interventions/materials/bedroom.svg';
// import bathroom from '../../../../../assets/interventions/materials/bathroom.svg';
// import kitchen from '../../../../../assets/interventions/materials/kitchen.svg';
// import EfficientFixturesicon from '../../../../../assets/efficientFixtures.svg';
import hwmvillicon from '../../../../../assets/interventions/HWM Villa Icon.svg';
import nstpIcon from '../../../../../assets/interventions/NSTPicon.svg';
import RrwhIcon from '../../../../../assets/interventions/rrwh.svg';
import SrwhIcon from '../../../../../assets/interventions/srwh.svg';
import { getEnabledAndActivatedInterventionsInCategory, getTextColourForIntervention } from '../../../interventions/intervention/utils';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import unit from '../../../../../utils/unitConfig';
import rsToCrore from '../../../../../utils/rsToCr';
import cement from '../../../../../assets/interventions/cement.svg';

const interventionToIconMap = {
  efficientFixtures: EfficientFixtures,
  swm: smartWaterMeter,
  wwm: biogasPlant,
  exteriorPaintsSunny: ExteriorPaints,
  exteriorPaintsNonSunny: ExteriorPaints,
  hwmVilla: hwmvillicon,
  hwmApartment: hwmvillicon,
  hwmClubhouse: hwmvillicon,
  hwmSwimmingPool: hwmvillicon,
  hwmPenthouse: hwmvillicon,
  spvApartment: solarPV,
  spvClubhouse: solarPV,
  spvVilla: solarPV,
  spvPenthouse: solarPV,
  efficientFixturesHealthFaucet: EfficientFixtures,
  efficientFixturesFaucet: EfficientFixtures,
  efficientFixturesKitchenSink: EfficientFixtures,
  efficientFixturesShower: EfficientFixtures,
  efficientFixturesDualFlush: dualflush,
  twr: greywaterReuse,
  nstp: nstpIcon,
  Energy: EnergyCustom,
  Water: WaterCustom,
  Waste: WasteCustom,
  Materials: MaterialsCustom,
  rrwh: RrwhIcon,
  srwh: SrwhIcon,
  sc: cement,
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: 290,
    padding: 18,
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
  },
}));

// check out for a common component
const SelectedInterv = ({ intervention }) => {
  let history = useHistory();

  const { getValue, getUnit } = useAppMetafireUtilities();

  const classes = useStyles();
  const params = useParams();
  const [filteredInterventions, setFilteredIntervention] = useState([]);
  const [selectedValue, setSelectedValue] = useState('top-opex');

  const { interventionCategoryContext, handleInterventionCategory } = useContext(InterventionCategoryContext);
  useEffect(() => {
    if (intervention) {
      if (selectedValue === 'top-capex') {
        const data = intervention?.categorized?.reduce((memo, categorized) => {
          const interventionsPerCategory = getEnabledAndActivatedInterventionsInCategory(categorized);
          memo = [...memo, ...interventionsPerCategory];
          return memo;
        }, []);
        const eData = data?.sort((a, b) => b?.quantities?.finance?.capex?.value - a?.quantities?.finance?.capex?.value).slice(0, 4);
        setFilteredIntervention(eData);
      } else if (selectedValue === 'top-opex') {
        const data = intervention?.categorized?.reduce((memo, categorized) => {
          const interventionsPerCategory = getEnabledAndActivatedInterventionsInCategory(categorized);
          memo = [...memo, ...interventionsPerCategory];
          return memo;
        }, []);
        const eData = data?.sort((a, b) => b?.quantities?.finance?.opex?.value - a?.quantities?.finance?.opex?.value).slice(0, 4);
        setFilteredIntervention(eData);
      } else {
        const data = intervention?.categorized?.find((categorized) => categorized?.name?.toLowerCase() === selectedValue);
        const eData = getEnabledAndActivatedInterventionsInCategory(data)
          .sort((a, b) => b?.quantities?.finance?.capex?.value - a?.quantities?.finance?.capex?.value)
          .slice(0, 4);
        setFilteredIntervention(eData);
      }
    }
  }, [intervention, selectedValue]);

  const handleChange = (e) => setSelectedValue(e.target.value);

  const energyCustomIcon = interventionToIconMap.Energy;
  const waterCustomIcon = interventionToIconMap.Water;
  const wasteCustomIcon = interventionToIconMap.Waste;

  const activateIntervention = (val) => {
    handleInterventionCategory(val);
    history.push(`/projects/${params?.id}/interventions`);
  };

  const pushToInterventionDetailsPage = (interventionId) => {
    history.push(`/projects/${params?.id}/interventions/${interventionId}`);
  };

  return (
    <>
      <Container className={classes.gridContainer}>
        <Grid container xs={12}>
          <Grid item xs={5}>
            <CustomSelectInterventions selectedvalue={selectedValue} handleChange={handleChange} />
          </Grid>
          <Grid item xs={7} style={{ textAlign: 'center', padding: '2%' }}>
            <Link style={{ fontSize: '12px', color: '#00C58A', lineHeight: '20%' }} to={`/projects/${params?.id}/interventions`}>
              View All Interventions
            </Link>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ paddingTop: 16, paddingLeft: 8 }}>
          {filteredInterventions && filteredInterventions.length !== 0 ? (
            filteredInterventions.map((intervention, index) => {
              return (
                <>
                  <Grid item xs={2} key={index}>
                    <img src={interventionToIconMap[intervention?.feature] || energyCustomIcon} alt={intervention?.name} style={{ height: '80%' }} />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left', paddingTop: '10px', cursor: 'pointer' }}>
                    <Typography
                      onClick={() => {
                        pushToInterventionDetailsPage(intervention.interventionProjectMappingId);
                      }}
                      variant="h6"
                      style={{ color: getTextColourForIntervention(intervention.feature) }}
                    >
                      {intervention?.name?.length > 15 ? intervention?.name?.substr(0, 17) + '..' : intervention?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'left', paddingTop: '10px' }}>
                    <Typography variant="h6" style={{ color: ' #666666' }}>
                      <b>
                        {selectedValue === 'top-capex'
                          ? `${rsToCrore('Rs', getValue(intervention?.quantities?.finance?.capex)) + ' ' + unit('cr')}`
                          : selectedValue === 'top-opex'
                          ? `${rsToCrore('Rs', getValue(intervention?.quantities?.finance?.opex)) + ' ' + unit('cr')}`
                          : `${numberWithCommas(Math.round(getValue(intervention?.quantities?.resource?.category)))} ${getUnit(
                              intervention?.quantities?.resource?.category
                            )}`}
                      </b>
                    </Typography>
                  </Grid>
                </>
              );
            })
          ) : (
            <>
              <Grid container item xs={12} style={{ paddingTop: '8%' }} justifyContent="center" alignItems="center">
                <Typography variant="h5" color="textSecondary" style={{ textAlign: 'center', lineHeight: '35px' }}>
                  No intervention
                  <br /> is currently active
                </Typography>
              </Grid>
              <Grid container item xs={12} style={{ paddingTop: '8%' }} justifyContent="center" alignItems="center">
                <PrimaryButton children={`Activate Interventions`} onClick={() => activateIntervention('energy')} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SelectedInterv;

// const interventions = [
//   { intervention: 'Light Management', icon: lightManagementSystem, capex: '5,03,440 kwh', color: '#FFAC49' },
//   { intervention: 'Efficient Fixtures', icon: EfficientFixtures, capex: '3,25,350 kL', color: '#3BC7EE' },
//   { intervention: 'Solar PV', icon: solarPV, capex: '3,00,000 kwh', color: '#FFAC49' },
//   { intervention: 'Collection & Segre..', icon: toxicNew, capex: '23,456 kg', color: '#BC8A5F' },
// ];
