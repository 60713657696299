export const FeatureSchemaFinalised = {
  structuralSteel: [
    {
      id: 'structuralSteel_savings',
      label: 'Savings',
      unit: 'tCO2e',
      type: 'number',
      tags: ['Resource', 'Intervention', 'result-resource', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'structuralSteel_savingsConverted',
      label: 'Equivalent to Saving',
      unit: 'Trees',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'structuralSteel_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'structuralSteel_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'structuralSteel_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'structuralSteel_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'structuralSteel_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'structuralSteel_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'structuralSteel_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'structuralSteel_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'structuralSteel_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'structuralSteel_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'structuralSteel_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'structuralSteel_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'structuralSteel_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'structuralSteel_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'structuralSteel_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'structuralSteel_typeOfSteel', label: 'Type Of Steel', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'structuralSteel_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'structuralSteel_builtUpArea', label: 'Built-up Area', unit: 'sqft', type: 'number', tags: [], rw: 'r' },
    { id: 'structuralSteel_quantityFactorOne', label: 'Steel Quantity Factor', unit: '(3.5 kg / sqft)', type: 'number', tags: [], rw: 'r' },
    { id: 'structuralSteel_quantityFactorTwo', label: null, unit: '(4 kg / sqft)', type: 'number', tags: [], rw: 'r' },
    { id: 'structuralSteel_quantityFactorThree', label: null, unit: '(4.5 kg / sqft)', type: 'number', tags: [], rw: 'r' },
    { id: 'structuralSteel_quantityFactorFour', label: null, unit: '(5 kg / sqft)', type: 'number', tags: [], rw: 'r' },
    { id: 'structuralSteel_projectQuantityFactorUserInput', label: 'Steel Quantity Factor (for Base Case)', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'structuralSteel__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_steelQuantity', label: 'Steel Quantity', unit: 'tons', type: 'UserActualDefault', tags: ['customizable'], rw: 'w' },
    { id: 'structuralSteel__pi_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_capexSource', label: 'CAPEX (per tonne)', unit: null, type: 'MultiplierSource', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_quantityFactorUserInput', label: 'Custom', unit: 'kg / sqft', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_quantityFactorOptionTwo', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_quantityFactorOptionThree', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_quantityFactorOptionFour', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_quantityFactorOptionFive', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'structuralSteel__pi_quantityFactorOptionOne', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
  ],
  hwmApartment: [
    {
      id: 'hwmApartment_savings',
      label: 'Savings(Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmApartment_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmApartment_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmApartment_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmApartment_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmApartment_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmApartment_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmApartment_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmApartment_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'hwmApartment_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'hwmApartment_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'hwmApartment_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'hwmApartment_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmApartment_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmApartment_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'hwmApartment_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'hwmApartment_hpAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_swhAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hybridSwhAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hpIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_swhIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hybridHpIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hybridSwhIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hpNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_swhNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hybridSwhNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hybridHpNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hwmApartmentVendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hybridVendorHpCapex', label: 'Heat Pump', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_hybridVendorSwhCapex', label: 'Solar Water Heater', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmApartment_oneBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmApartment_twoBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmApartment_threeBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmApartment_fourBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmApartment_studio', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmApartment_rooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmApartment_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'hwmApartment_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'hwmApartment_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'hwmApartment__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userHpCapacity', label: 'Heat Pump Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userSwhCapacity', label: 'Solar Water Heater Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userHybridHpCapacity', label: 'Heat Pump Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userHybridSwhCapacity', label: 'Solar Water Heater Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_swhType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_hpType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_swhVariant', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_hpHeatingSpeed', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_hybridSwhVariant', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_hybridHpHeatingSpeed', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_demandByArea', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_demandByBath', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_demandAssumptionType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_demandAssumption', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_customHybridSwhCapex', label: 'Solar Water Heater', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_customHybridHpCapex', label: 'Heat Pump', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_capexSourceType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userCityResidentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userRooftopAreaPerHp', label: 'Rooftop Area per HP LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmApartment__pi_userRooftopAreaPerSwh', label: 'Rooftop Area per SWH LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmApartment__pi_userHotWaterHours', label: 'Hot Water Usage Hours', unit: 'hrs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userNumberOfShafts', label: 'Number of Plumbing Shafts', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userLpcdPerOccupant', label: 'Per Occupant', unit: 'LPCD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userLpdPerApartment', label: 'Per Apartment', unit: 'LPCD per Apartment', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_userHotWaterFloors', label: 'Number of Floors', unit: 'Floors', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_baseCaseSwhCapacity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'hwmApartment__pi_option', label: 'System', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
  ],
  exteriorPaintsNonSunny: [
    { id: 'exteriorPaintsNonSunny_brand', label: 'Brand', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_productName', label: 'Product Name', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_vocLevel', label: 'VOC Level', unit: null, type: 'string', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_co2EmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kg', type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_floorToFloorHeight', label: 'Floor-to-Floor height', unit: 'm', type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_perimeter', label: 'Perimeter', unit: 'm', type: 'number', tags: [], rw: 'r' },
    {
      id: 'exteriorPaintsNonSunny_savings',
      label: 'Savings(Annual)',
      unit: 'Rs',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'exteriorPaintsNonSunny_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'exteriorPaintsNonSunny_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    {
      id: 'exteriorPaintsNonSunny_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'exteriorPaintsNonSunny_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    {
      id: 'exteriorPaintsNonSunny_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'exteriorPaintsNonSunny_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention'],
      rw: 'r',
    },
    { id: 'exteriorPaintsNonSunny_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_paybackPeriod', label: 'Payback Period', unit: 'Yrs', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_numberOfFloors', label: 'No. of Floors', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_wwr', label: 'WWR', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'exteriorPaintsNonSunny_treesSaved', label: 'Trees Equivalent Saved', unit: 'nos', type: 'number', tags: ['result-resource'], rw: 'r' },
    { id: 'exteriorPaintsNonSunny__pi_capexSource', label: 'CAPEX (Rs/L)', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'exteriorPaintsNonSunny__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'exteriorPaintsNonSunny__pi_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'exteriorPaintsNonSunny__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'project__pi_sunIncidentArea', label: 'Sun Incident Area', unit: '%', type: 'UserActualDefault', tags: ['project-input', 'customizable'], rw: 'w' },
    { id: 'exteriorPaintsNonSunny__pi_numberOfCoats', label: 'Number of Coats', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'exteriorPaintsNonSunny__pi_coverageArea', label: 'Coverage Area', unit: 'Sqm', type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  exteriorPaintsSunny: [
    { id: 'exteriorPaintsSunny_brand', label: 'Brand', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'exteriorPaintsSunny_productName', label: 'Product Name', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'exteriorPaintsSunny_vocLevel', label: 'VOC Level', unit: null, type: 'string', tags: ['result-resource'], rw: 'r' },
    { id: 'exteriorPaintsSunny_co2EmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kg', type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_floorToFloorHeight', label: 'Floor-to-Floor height', unit: 'm', type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_numberOfFloors', label: 'No. of Floors', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_perimeter', label: 'Perimeter', unit: 'm', type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_wwr', label: 'WWR', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_savings', label: 'Savings(Annual)', unit: 'Rs', type: 'number', tags: ['Resource', 'Intervention'], rw: 'r' },
    {
      id: 'exteriorPaintsSunny_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'exteriorPaintsSunny_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention'], rw: 'r' },
    { id: 'exteriorPaintsSunny_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    { id: 'exteriorPaintsSunny_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention'], rw: 'r' },
    { id: 'exteriorPaintsSunny_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    {
      id: 'exteriorPaintsSunny_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'exteriorPaintsSunny_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention'],
      rw: 'r',
    },
    { id: 'exteriorPaintsSunny_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'exteriorPaintsSunny_paybackPeriod', label: 'Payback Period', unit: 'Yrs', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'exteriorPaintsSunny_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'exteriorPaintsSunny_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'exteriorPaintsSunny_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'exteriorPaintsSunny_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'exteriorPaintsSunny_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny_treesSaved', label: 'Trees Equivalent Saved', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'exteriorPaintsSunny__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'exteriorPaintsSunny__pi_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'exteriorPaintsSunny__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'exteriorPaintsSunny__pi_capexMultiplierSource', label: null, unit: null, type: 'MultiplierSource', tags: [], rw: 'w' },
    { id: 'project__pi_sunIncidentArea', label: 'Sun Incident Area', unit: '%', type: 'UserActualDefault', tags: ['project-input', 'customizable'], rw: 'w' },
    { id: 'exteriorPaintsSunny__pi_numberOfCoats', label: 'Number of Coats', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'exteriorPaintsSunny__pi_coverageArea', label: 'Coverage Area', unit: 'Sqm', type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  nstp: [
    { id: 'nstp_savings', label: 'Savings(Annual)', unit: 'KL', type: 'number', tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'nstp_savingsConverted',
      label: 'Avoided Annually',
      unit: 'Tankers',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'nstp_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'nstp_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'nstp_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'nstp_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'nstp_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'nstp_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_nstpArea', label: 'Area', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'nstp_stpCapacity', label: 'STP Capacity', unit: 'KLD', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'nstp_coTwoEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'nstp_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'nstp_poweredAnnualy', label: 'Avoided Annually', unit: 'Water Tanker', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'nstp_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_penthouseOneBHK', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_penthouseTwoBHK', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_penthouseThreeBHK', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_penthouseFourBHK', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'nstp_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'nstp_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'nstp_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'nstp_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'nstp__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'nstp__pi_capexMultiplierSource', label: 'Selected Vendor', unit: 'Rs', type: 'string', tags: [], rw: 'w' },
    { id: 'nstp__pi_userTankerPrice', label: 'Water Tanker Price per KL', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'nstp__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
  ],
  twr: [
    { id: 'twr_savings', label: 'Savings(Annual)', unit: 'KL', type: 'number', tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'twr_savingsConverted',
      label: 'Avoided Annually',
      unit: 'Tankers',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'twr_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'twr_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'twr_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'twr_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'twr_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'twr_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'twr_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'twr_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'twr_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'twr_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'twr_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'twr_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'twr_coTwoEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'twr_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'twr_poweredAnnualy', label: 'Avoided Annually', unit: 'Water Tanker', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'twr_dischargePercentage', label: 'Water Discharge to Sewage Lines', unit: '%', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'twr_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'twr_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_penthouseOneBHK', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_penthouseTwoBHK', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_penthouseThreeBHK', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_penthouseFourBHK', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'twr_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'twr_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'twr_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'twr__pi_userStpEfficiency', label: 'STP Efficiency', unit: 'KLD', type: 'UserActualDefault', tags: ['Report'], rw: 'w' },
    { id: 'twr__pi_userRoCapacity', label: 'RO Capacity', unit: 'KLD', type: 'UserActualDefault', tags: ['customizable', 'uiRound:6'], rw: 'w' },
    { id: 'twr__pi_userStpCapacity', label: 'STP Capacity', unit: 'KLD', type: 'UserActualDefault', tags: ['customizable', 'uiRound:6'], rw: 'w' },
    { id: 'twr__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'twr__pi_capexMultiplierSource', label: 'Selected Vendor', unit: 'Rs', type: 'string', tags: [], rw: 'w' },
    { id: 'twr__pi_userTankerPrice', label: 'Water Tanker Price per KL', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'twr__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'twr__pi_flushing', label: 'Flushing', unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'twr__pi_carWash', label: 'Car Wash', unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'twr__pi_commonAreaCleaning', label: 'Common Area Cleaning', unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'twr__pi_landScaping', label: 'Landscape', unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'twr__pi_pool', label: 'Pool', unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'twr__pi_domestic', label: 'Domestic Consumption', unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'twr__pi_reuseTreatedWater', label: 'Reuse Treated Water', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'twr__pi_userOffsiteReusePrice', label: 'Treated Water Price', unit: 'Rs/KL', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'twr__pi_offsiteReuse', label: 'Construction / Commercial / Industrial', unit: null, type: 'boolean', tags: [], rw: 'w' },
  ],
  spvApartment: [
    {
      id: 'spvApartment_savings',
      label: 'Savings (Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvApartment_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvApartment_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvApartment_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvApartment_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'spvApartment_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvApartment_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'spvApartment_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvApartment_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'spvApartment_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'spvApartment_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'spvApartment_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'spvApartment_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvApartment_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvApartment_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'spvApartment_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'spvApartment_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'spvApartment_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'spvApartment_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'spvApartment_rooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment_unavailableRooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'spvApartment__pi_baseCaseCapacity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'spvApartment__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvApartment__pi_capexSource', label: 'CAPEX (per unit installed)', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvApartment__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'spvApartment__pi_capacity', label: 'System Capacity', unit: 'kWp', type: 'UserActualDefault', tags: ['customizable', 'uiRound:6'], rw: 'w' },
    { id: 'spvApartment__pi_orientation', label: 'Orientation', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvApartment__pi_inverterType', label: 'Inverter Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvApartment__pi_panelType', label: 'Solar Panel Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvApartment__pi_residentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'spvApartment__pi_panelCoverage', label: 'Panel Coverage', unit: '%', type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  efficientFixturesHealthFaucet: [
    {
      id: 'efficientFixturesHealthFaucet_savings',
      label: 'Savings(Annual)',
      unit: 'Rs',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesHealthFaucet_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesHealthFaucet_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesHealthFaucet_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesHealthFaucet_opex',
      label: 'OPEX Savings',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Opex', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesHealthFaucet_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesHealthFaucet_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_paybackPeriod', label: 'Payback Period', unit: 'Yrs', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesHealthFaucet_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesHealthFaucet_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: [], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_brand', label: 'Brand', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_productName', label: 'Product Name', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_fixtureFlowRate', label: 'Fixture Flow Rate', unit: 'lpm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_vendorPerUnitCapex', label: 'Per Unit Capex', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_rooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_pentHouseOneBhk', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_pentHouseTwoBhk', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_pentHouseThreeBhk', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_pentHouseFourBhk', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_vendorCapex', label: 'Selected Vendor ', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesHealthFaucet__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'efficientFixturesHealthFaucet__pi_capexMultiplierSource', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    {
      id: 'efficientFixturesHealthFaucet__pi_waterTankerPricePerKL',
      label: 'Water Tanker Price per KL',
      unit: 'Rs',
      type: 'UserActualDefault',
      tags: [],
      rw: 'w',
    },
    {
      id: 'project__pi_userHousekeepingRefOccupancy',
      label: 'Housekeeping Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    {
      id: 'project__pi_clubhouseRefOccupancy',
      label: 'Club House Reference Occupancy ',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    { id: 'efficientFixturesHealthFaucet__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'efficientFixturesHealthFaucet_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
  ],
  efficientFixturesFaucet: [
    {
      id: 'efficientFixturesFaucet_savings',
      label: 'Savings(Annual)',
      unit: 'Rs',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesFaucet_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesFaucet_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesFaucet_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesFaucet_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesFaucet_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesFaucet_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesFaucet_paybackPeriod', label: 'Payback Period', unit: 'Yrs', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesFaucet_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesFaucet_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesFaucet_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'efficientFixturesFaucet_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'efficientFixturesFaucet_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesFaucet_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesFaucet_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'efficientFixturesFaucet_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'efficientFixturesFaucet_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: [], rw: 'r' },
    { id: 'efficientFixturesFaucet_brand', label: 'Brand', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesFaucet_productName', label: 'Product Name', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesFaucet_fixtureFlowRate', label: 'Fixture Flow Rate', unit: null, type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesFaucet_vendorPerUnitCapex', label: 'Per Unit Capex', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesFaucet_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_rooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_pentHouseOneBhk', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_pentHouseTwoBhk', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_pentHouseThreeBhk', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_pentHouseFourBhk', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesFaucet_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesFaucet_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesFaucet__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'efficientFixturesFaucet__pi_capexMultiplierSource', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'efficientFixturesFaucet__pi_waterTankerPricePerKL', label: 'Water Tanker Price per KL', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    {
      id: 'project__pi_userHousekeepingRefOccupancy',
      label: 'Housekeeping Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    {
      id: 'project__pi_clubhouseRefOccupancy',
      label: 'Club House Reference Occupancy ',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    { id: 'efficientFixturesFaucet__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'efficientFixturesFaucet_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
  ],
  efficientFixturesKitchenSink: [
    {
      id: 'efficientFixturesKitchenSink_savings',
      label: 'Savings(Annual)',
      unit: 'Rs',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesKitchenSink_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesKitchenSink_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesKitchenSink_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesKitchenSink_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesKitchenSink_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesKitchenSink_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_paybackPeriod', label: 'Payback Period', unit: 'Yrs', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesKitchenSink_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesKitchenSink_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: [], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_brand', label: 'Brand', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_productName', label: 'Product Name', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_fixtureFlowRate', label: 'Fixture Flow Rate', unit: null, type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_vendorPerUnitCapex', label: 'Per Unit Capex', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_rooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_pentHouseOneBhk', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_pentHouseTwoBhk', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_pentHouseThreeBhk', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_pentHouseFourBhk', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesKitchenSink_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesKitchenSink__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'efficientFixturesKitchenSink__pi_capexMultiplierSource', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    {
      id: 'efficientFixturesKitchenSink__pi_waterTankerPricePerKL',
      label: 'Water Tanker Price per KL',
      unit: 'Rs',
      type: 'UserActualDefault',
      tags: [],
      rw: 'w',
    },
    {
      id: 'project__pi_userHousekeepingRefOccupancy',
      label: 'Housekeeping Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    {
      id: 'project__pi_clubhouseRefOccupancy',
      label: 'Club House Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    { id: 'efficientFixturesKitchenSink__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'efficientFixturesKitchenSink_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
  ],
  efficientFixturesShower: [
    {
      id: 'efficientFixturesShower_savings',
      label: 'Savings(Annual)',
      unit: 'Rs',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesShower_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesShower_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesShower_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesShower_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesShower_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesShower_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesShower_paybackPeriod', label: 'Payback Period', unit: 'Yrs', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesShower_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesShower_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesShower_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'efficientFixturesShower_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'efficientFixturesShower_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesShower_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesShower_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'efficientFixturesShower_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'efficientFixturesShower_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: [], rw: 'r' },
    { id: 'efficientFixturesShower_brand', label: 'Brand', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesShower_productName', label: 'Product Name', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesShower_fixtureFlowRate', label: 'Fixture Flow Rate', unit: null, type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesShower_vendorPerUnitCapex', label: 'Per Unit Capex', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesShower_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_rooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_pentHouseOneBhk', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_pentHouseTwoBhk', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_pentHouseThreeBhk', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_pentHouseFourBhk', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesShower_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesShower_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesShower__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'efficientFixturesShower__pi_capexMultiplierSource', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'efficientFixturesShower__pi_waterTankerPricePerKL', label: 'Water Tanker Price per KL', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    {
      id: 'project__pi_userHousekeepingRefOccupancy',
      label: 'Housekeeping Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    {
      id: 'project__pi_clubhouseRefOccupancy',
      label: 'Club House Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    { id: 'efficientFixturesShower__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'efficientFixturesShower_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
  ],
  efficientFixturesDualFlush: [
    {
      id: 'efficientFixturesDualFlush_savings',
      label: 'Savings(Annual)',
      unit: 'Rs',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesDualFlush_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesDualFlush_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention'],
      rw: 'r',
    },
    {
      id: 'efficientFixturesDualFlush_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesDualFlush_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesDualFlush_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesDualFlush_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_paybackPeriod', label: 'Payback Period', unit: 'Yrs', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    {
      id: 'efficientFixturesDualFlush_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'efficientFixturesDualFlush_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'efficientFixturesDualFlush_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'efficientFixturesDualFlush_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: [], rw: 'r' },
    { id: 'efficientFixturesDualFlush_brand', label: 'Brand', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_productName', label: 'Product Name', unit: null, type: 'string', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_vendorPerUnitCapex', label: 'Per Unit Capex', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesDualFlush_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_rooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_pentHouseOneBhk', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_pentHouseTwoBhk', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_pentHouseThreeBhk', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_pentHouseFourBhk', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesDualFlush_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'efficientFixturesDualFlush_flushRateFull', label: 'Flush Rate - Full', unit: 'lpf', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesDualFlush_flushRateHalf', label: 'Flush Rate - Half', unit: 'lpf', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'efficientFixturesDualFlush__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'efficientFixturesDualFlush__pi_capexMultiplierSource', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    {
      id: 'efficientFixturesDualFlush__pi_waterTankerPricePerKL',
      label: 'Water Tanker Price per KL',
      unit: 'Rs',
      type: 'UserActualDefault',
      tags: [],
      rw: 'w',
    },
    {
      id: 'project__pi_userHousekeepingRefOccupancy',
      label: 'Housekeeping Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    {
      id: 'project__pi_clubhouseRefOccupancy',
      label: 'Club House Reference Occupancy',
      unit: null,
      type: 'UserActualDefault',
      tags: ['project-input'],
      rw: 'w',
    },
    { id: 'efficientFixturesDualFlush__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'efficientFixturesDualFlush_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
  ],
  hwmClubhouse: [
    {
      id: 'hwmClubhouse_savings',
      label: 'Savings(Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmClubhouse_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmClubhouse_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmClubhouse_hwmClubhouseVendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'any', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_defaultHotWaterFloors', label: 'Number of Floors', unit: 'Floors', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmClubhouse_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmClubhouse_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmClubhouse_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmClubhouse_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmClubhouse_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'hwmClubhouse_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'hwmClubhouse_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'hwmClubhouse_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'hwmClubhouse_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmClubhouse_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmClubhouse_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'hwmClubhouse_hpAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_swhAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_hpIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_swhIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_hpNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_swhNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_clubhouseRooftopArea', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmClubhouse_swimmingPoolSurfaceArea', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmClubhouse_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'hwmClubhouse_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'hwmClubhouse__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_userHpCapacity', label: 'Heat Pump Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_userSwhCapacity', label: 'Solar Water Heater Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_swhType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_hpType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_swhVariant', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_hpHeatingSpeed', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_demandAssumptionType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_capexSourceType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_userCityResidentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_userRooftopAreaPerHp', label: 'Rooftop Area per HP LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmClubhouse__pi_userRooftopAreaPerSwh', label: 'Rooftop Area per SWH LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmClubhouse__pi_userHotWaterHours', label: 'Hot Water Usage Hours', unit: 'hrs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_userNumberOfShafts', label: 'Number of Plumbing Shafts', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_userLpcdPerOccupant', label: 'Per Occupant', unit: 'LPCD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmClubhouse__pi_option', label: 'System', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'hwmClubhouse__pi_userPoolAreaPerOccupant', label: 'Pool Area Per Occupant', unit: 'LPCD', type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  hwmSwimmingPool: [
    {
      id: 'hwmSwimmingPool_savings',
      label: 'Savings(Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmSwimmingPool_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmSwimmingPool_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmSwimmingPool_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmSwimmingPool_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmSwimmingPool_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmSwimmingPool_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmSwimmingPool_hwmSwimmingPoolVendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'any', tags: [], rw: 'r' },
    {
      id: 'hwmSwimmingPool_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmSwimmingPool_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'hwmSwimmingPool_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'hwmSwimmingPool_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'hwmSwimmingPool_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'hwmSwimmingPool_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmSwimmingPool_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmSwimmingPool_individualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'hwmSwimmingPool_numberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_swimmingPoolSurfaceArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmSwimmingPool_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'hwmSwimmingPool_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'hwmSwimmingPool__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_userHpCapacity', label: 'Heat Pump Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_hpType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_hpHeatingSpeed', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_capexSourceType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_userCityResidentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_userRooftopAreaPerHp', label: 'Rooftop Area per HP LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_userhotWaterHours', label: 'Hot Water Usage Hours', unit: 'hrs', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_userNumberOfShafts', label: 'Number of Plumbing Shafts', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmSwimmingPool__pi_option', label: 'System', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
  ],
  swm: [
    { id: 'swm_savings', label: 'Savings(Annual)', unit: 'KL', type: 'number', tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'swm_savingsConverted',
      label: 'Avoided Annually',
      unit: 'Tankers',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'swm_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'swm_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'swm_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'swm_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'swm_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'swm_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'swm_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'swm_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'swm_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'swm_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'swm_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'swm_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'swm_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'swm_clubhouse', label: 'Club House', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'swm_supplySideTanker', label: 'Supply Side - Tanker', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'swm_supplySideBorewell', label: 'Supply Side - Borewell', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'swm_opexCosts', label: 'OPEX Costs (AMC + Charges)', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_co2EmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_totalNumberOfSensors', label: 'Total No. Of Sensors', unit: null, type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'swm_studio', label: 'Studio', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_penthouseOneBHK', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_penthouseThreeBHK', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_penthouseFourBHK', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_penthouseTwoBHK', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_penthouseStudio', label: null, unit: 'units', type: 'number', tags: [], rw: 'r' },
    { id: 'swm_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'swm_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'swm_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'swm__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'swm__pi_waterTankerPricePerKL', label: 'Water Tanker Price per KL', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'swm__pi_userOtherSensor', label: 'Other', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'swm__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'swm__pi_userInletsPerStudio', label: 'Inlets per Studio Apartment', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'swm__pi_userInletsPerOneBHK', label: 'Inlets per 1BHK Apartment', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'swm__pi_userInletsPerTwoBHK', label: 'Inlets per 2BHK Apartment', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'swm__pi_userInletsPerThreeBHK', label: 'Inlets per 3BHK Apartment', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'swm__pi_userInletsPerFourBHK', label: 'Inlets per 4BHK Apartment', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'swm__pi_otherSensor', label: 'Others', unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'swm__pi_capexSourceMultiplier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'swm__pi_clubhouseUtilitySensor', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'swm__pi_supplySideTankerUtilitySensor', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
    { id: 'swm__pi_supplySideBorewellUtilitySensor', label: null, unit: null, type: 'boolean', tags: [], rw: 'w' },
  ],
  spvPenthouse: [
    {
      id: 'spvPenthouse_savings',
      label: 'Savings (Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvPenthouse_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvPenthouse_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvPenthouse_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvPenthouse_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'spvPenthouse_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvPenthouse_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'spvPenthouse_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvPenthouse_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'spvPenthouse_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'spvPenthouse_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'spvPenthouse_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'spvPenthouse_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvPenthouse_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvPenthouse_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'spvPenthouse_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'spvPenthouse_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'spvPenthouse_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'spvPenthouse_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'spvPenthouse_availablePenthouseRooftopArea', label: 'Available Penthouse Rooftop Area', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'spvPenthouse__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvPenthouse__pi_capexSource', label: 'CAPEX (per unit installed)', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvPenthouse__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'spvPenthouse__pi_capacity', label: 'System Capacity', unit: 'kWp', type: 'UserActualDefault', tags: ['customizable', 'uiRound:6'], rw: 'w' },
    { id: 'spvPenthouse__pi_orientation', label: 'Orientation', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvPenthouse__pi_inverterType', label: 'Inverter Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvPenthouse__pi_panelType', label: 'Solar Panel Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvPenthouse__pi_residentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'spvPenthouse__pi_panelCoverage', label: 'Panel Coverage', unit: '%', type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  hwmVilla: [
    { id: 'hwmVilla_hwmVillaVendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'any', tags: [], rw: 'r' },
    {
      id: 'hwmVilla_savings',
      label: 'Savings(Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmVilla_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmVilla_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'hwmVilla_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'hwmVilla_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'hwmVilla_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmVilla_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmVilla_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmVilla_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'hwmVilla_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'hwmVilla_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'hwmVilla_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'hwmVilla_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmVilla_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmVilla_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'hwmVilla_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'hwmVilla_hpAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_swhAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_hpIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_swhIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_hpNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_swhNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmVilla_villaOneBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmVilla_villaTwoBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmVilla_villaThreeBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmVilla_villaFourBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmVilla_availableVillaRooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmVilla_unavailableRooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmVilla_floorToFloorHeight', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmVilla_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'hwmVilla_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'hwmVilla_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'hwmVilla__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_userHpCapacity', label: 'Heat Pump Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_userSwhCapacity', label: 'Solar Water Heater Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_swhType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_hpType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_swhVariant', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_hpHeatingSpeed', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_demandByBathType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_demandAssumptionType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_capexSourceType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_userCityResidentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_userRooftopAreaPerHp', label: 'Rooftop Area per HP LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmVilla__pi_userRooftopAreaPerSwh', label: 'Rooftop Area per SWH LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmVilla__pi_userHotWaterHours', label: 'Hot Water Usage Hours', unit: 'hrs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_userNumberOfShafts', label: 'Number of Plumbing Shafts', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_userLpcdPerOccupant', label: 'Per Occupant', unit: 'LPCD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_userLpdPerVilla', label: 'Per Villa', unit: 'LPCD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmVilla__pi_option', label: 'System', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
  ],
  hwmPenthouse: [
    { id: 'hwmPenthouse_hwmPenthouseVendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'any', tags: [], rw: 'r' },
    {
      id: 'hwmPenthouse_savings',
      label: 'Savings(Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmPenthouse_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmPenthouse_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmPenthouse_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmPenthouse_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmPenthouse_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'hwmPenthouse_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'hwmPenthouse_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'hwmPenthouse_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'hwmPenthouse_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'hwmPenthouse_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'hwmPenthouse_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'hwmPenthouse_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmPenthouse_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'hwmPenthouse_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'hwmPenthouse_hpAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_swhAreaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_hpIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_swhIndividualProductCapacity', label: 'Individual Product Capacity', unit: 'LPD', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_hpNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_swhNumberOfProducts', label: 'No. of Products', unit: 'nos', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_penthouseStudio', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmPenthouse_penthouseOneBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmPenthouse_penthouseTwoBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmPenthouse_penthouseThreeBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmPenthouse_penthouseFourBHK', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmPenthouse_availablePenthouseRooftopArea', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmPenthouse_floorToFloorHeight', label: 'Rooftop Area', unit: 'Sqm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'hwmPenthouse_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'hwmPenthouse_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'hwmPenthouse__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_userHpCapacity', label: 'Heat Pump Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_userSwhCapacity', label: 'Solar Water Heater Capacity', unit: 'LPD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_swhType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_hpType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_swhVariant', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_hpHeatingSpeed', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_demandByBathType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_demandAssumptionType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_capexSourceType', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_userCityResidentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_userRooftopAreaPerHp', label: 'Rooftop Area per HP LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmPenthouse__pi_userRooftopAreaPerSwh', label: 'Rooftop Area per SWH LPD', unit: 'Sqm', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'hwmPenthouse__pi_userHotWaterHours', label: 'Hot Water Usage Hours', unit: 'hrs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_userNumberOfShafts', label: 'Number of Plumbing Shafts', unit: 'nos', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_userLpcdPerOccupant', label: 'Per Occupant', unit: 'LPCD', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_userLpdPerPenthouse', label: 'Per Apartment', unit: 'LPCD per Apartment', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'hwmPenthouse__pi_option', label: 'System', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
  ],
  spvVilla: [
    {
      id: 'spvVilla_savings',
      label: 'Savings (Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvVilla_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvVilla_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvVilla_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'spvVilla_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'spvVilla_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'spvVilla_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'spvVilla_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvVilla_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'spvVilla_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'spvVilla_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'spvVilla_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'spvVilla_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvVilla_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvVilla_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'spvVilla_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'spvVilla_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'spvVilla_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'spvVilla_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'spvVilla_availableVillaRooftopArea', label: 'Available Villa Rooftop Area', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'spvVilla__pi_baseCaseCapacity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'spvVilla__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvVilla__pi_capexSource', label: 'CAPEX (per unit installed)', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvVilla__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'spvVilla__pi_capacity', label: 'System Capacity', unit: 'kWp', type: 'UserActualDefault', tags: ['customizable', 'uiRound:6'], rw: 'w' },
    { id: 'spvVilla__pi_orientation', label: 'Orientation', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvVilla__pi_inverterType', label: 'Inverter Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvVilla__pi_panelType', label: 'Solar Panel Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvVilla__pi_residentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'spvVilla__pi_panelCoverage', label: 'Panel Coverage', unit: '%', type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  wwm: [
    { id: 'wwm_savings', label: 'Savings(Annual)', unit: 'kg', type: 'number', tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'wwm_savingsConverted',
      label: 'Avoided Annually',
      unit: 'Waste Trucks',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'wwm_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'wwm_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'wwm_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'wwm_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'wwm_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'wwm_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'wwm_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'wwm_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'wwm_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'wwm_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'wwm_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'wwm_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'wwm_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'wwm_wwmMandate', label: 'Wet Waste Management Mandate', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'wwm_kgLpgPerKgWetWaste', label: 'kg LPG per kg wet waste', unit: 'kg', type: 'number', tags: [], rw: 'r' },
    { id: 'wwm_wetContentPerKgWaste', label: 'Wet content per kg waste', unit: 'kg', type: 'number', tags: [], rw: 'r' },
    { id: 'wwm_oneBHK', label: '1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_twoBHK', label: '2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_threeBHK', label: '3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_fourBHK', label: '4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_studio', label: 'Studio', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_penthouseOneBHK', label: 'PH 1 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_penthouseTwoBHK', label: 'PH 2 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_penthouseThreeBHK', label: 'PH 3 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_penthouseFourBHK', label: 'PH 4 BHK', unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_subcategory', label: null, unit: null, type: 'string', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_penthouseEnabled', label: null, unit: null, type: 'boolean', tags: ['project-input'], rw: 'r' },
    { id: 'wwm_mandateMsg', label: null, unit: null, type: 'string', tags: ['Mandate'], rw: 'r' },
    { id: 'wwm_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'wwm_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'wwm_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'wwm_kwhPerBiogasCum', label: 'kWh per Biogas cum', unit: 'kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'wwm_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'wwm_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'wwm_disableBiogas', label: null, unit: null, type: 'boolean', tags: [], rw: 'r' },
    { id: 'wwm_kgToCumConversion', label: 'kg to cum conversion', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'wwm_emissionFactorForLpg', label: 'CO2e Emission Factor', unit: 'kg/CO2e/kL', type: 'number', tags: [], rw: 'r' },
    { id: 'wwm__pi_endUse', label: 'End Use', unit: null, type: 'UserActualDefault', tags: ['customizable'], rw: 'w' },
    { id: 'wwm__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'wwm__pi_residentialUtilityTariff', label: 'Electricity Price per kWh', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'wwm__pi_lpgPricePerKg', label: 'LPG Price per Kg', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'wwm__pi_capacity', label: 'System Capacity', unit: 'kg', type: 'UserActualDefault', tags: ['customizable'], rw: 'w' },
    { id: 'wwm__pi_capexMultiplierSource', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'wwm__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
  ],
  spvClubhouse: [
    {
      id: 'spvClubhouse_savings',
      label: 'Savings (Annual)',
      unit: 'kWh',
      type: 'number',
      tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvClubhouse_carbon',
      label: 'Carbon Mitigated',
      unit: 'tCO2e',
      type: 'number',
      tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvClubhouse_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    {
      id: 'spvClubhouse_capex',
      label: 'CAPEX Increase',
      unit: 'Rs',
      type: 'number',
      tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvClubhouse_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'spvClubhouse_capexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['CapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvClubhouse_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'spvClubhouse_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'spvClubhouse_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'spvClubhouse_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'spvClubhouse_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'spvClubhouse_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'spvClubhouse_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvClubhouse_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'spvClubhouse_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'spvClubhouse_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'spvClubhouse_cityEmissionFactor', label: 'CO2e Emission Factor', unit: 'kg CO2e/kWh', type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_commonArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_homeAppliances', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_opexConverted', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_residentialFan', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_residentialHotWater', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_residentialHVAC', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_residentialLighting', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'spvClubhouse_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'spvClubhouse_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'spvClubhouse_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    {
      id: 'spvClubhouse_availableClubhouseRooftopArea',
      label: 'Available Clubhouse Rooftop Area',
      unit: 'Sqm',
      type: 'number',
      tags: ['project-input'],
      rw: 'r',
    },
    { id: 'spvClubhouse__pi_baseCaseCapacity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'spvClubhouse__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvClubhouse__pi_capexSource', label: 'CAPEX (per unit installed)', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvClubhouse__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'spvClubhouse__pi_capacity', label: 'System Capacity', unit: 'kWp', type: 'UserActualDefault', tags: ['customizable', 'uiRound:6'], rw: 'w' },
    { id: 'spvClubhouse__pi_orientation', label: 'Orientation', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'spvClubhouse__pi_inverterType', label: 'Inverter Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvClubhouse__pi_panelType', label: 'Solar Panel Technology', unit: null, type: 'string', tags: ['customizable'], rw: 'w' },
    { id: 'spvClubhouse__pi_residentialUtilityTariff', label: 'Electricity Cost per kWh', unit: 'Rs', type: 'UserActualDefault', tags: ['uiRound:6'], rw: 'w' },
    { id: 'spvClubhouse__pi_panelCoverage', label: 'Panel Coverage', unit: '%', type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  csp: [
    { id: 'csp_savings', label: 'Savings(Annual)', unit: 'kg', type: 'number', tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'csp_savingsConverted',
      label: 'Avoided Annually',
      unit: 'Waste Trucks',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'csp_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'csp_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'csp_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'csp_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'csp_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'csp_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'csp_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'csp_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'csp_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource', 'uiRound:0'], rw: 'r' },
    { id: 'csp_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'csp_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'csp_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'csp_donuts', label: null, unit: null, type: 'Donuts', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_overview', label: null, unit: null, type: 'Overview', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_studio', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_oneBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_twoBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_threeBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_fourBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_penthouseOneBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_penthouseTwoBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_penthouseThreeBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_penthouseFourBHK', label: null, unit: 'units', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'csp_areaRequired', label: 'Area Required', unit: 'Sqm', type: 'number', tags: ['customizable'], rw: 'r' },
    { id: 'csp_dailyEWaste', label: 'Daily E-Waste generated', unit: 'kg', type: 'number', tags: ['customizable', 'uiRound:0'], rw: 'r' },
    { id: 'csp_dailyMedicalWaste', label: 'Daily Medical Waste generated', unit: 'kg', type: 'number', tags: ['customizable', 'uiRound:0'], rw: 'r' },
    { id: 'csp_dailyDryWaste', label: 'Daily Dry Waste generated', unit: 'kg', type: 'number', tags: ['customizable', 'uiRound:0'], rw: 'r' },
    { id: 'csp_dryWastePerKg', label: 'Dry waste per kg Waste', unit: 'kg', type: 'number', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_eWastePerKg', label: 'E-waste per kg Waste', unit: 'kg', type: 'number', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_medicalWastePerKg', label: 'Medical waste per kg Waste', unit: 'kg', type: 'number', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_emissionFactorForDryWaste', label: 'Dry Waste Emission Factor', unit: 'kg CO2e/kg', type: 'number', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_emissionFactorForEWaste', label: 'E-waste Emission Factor', unit: 'kg CO2e/kg', type: 'number', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_emissionFactorForMedicalWaste', label: 'Medical Waste Emission Factor', unit: 'kg CO2e/kg', type: 'number', tags: ['uiRound:0'], rw: 'r' },
    { id: 'csp_projectSubCategory', label: null, unit: null, type: 'string', tags: ['project-input'], rw: 'r' },
    { id: 'csp_penthouseEnabled', label: null, unit: null, type: 'boolean', tags: ['project-input'], rw: 'r' },
    { id: 'csp_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'csp_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'csp_mandateMsg', label: null, unit: null, type: 'string', tags: ['Mandate'], rw: 'r' },
    { id: 'csp__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'csp__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'csp__pi_capexMultiplierSource', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
  ],
  rrwh: [
    { id: 'rrwh_savings', label: 'Savings(Annual)', unit: 'KL', type: 'number', tags: ['result-resource', 'Resource', 'Intervention'], rw: 'r' },
    {
      id: 'rrwh_savingsConverted',
      label: 'Avoided Annually',
      unit: 'Water Tanker',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'rrwh_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention'], rw: 'r' },
    { id: 'rrwh_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention'], rw: 'r' },
    { id: 'rrwh_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    { id: 'rrwh_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention'], rw: 'r' },
    { id: 'rrwh_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'rrwh_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'rrwh_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    { id: 'rrwh_totalCapexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['_TotalCapexConverted', 'Intervention'], rw: 'r' },
    { id: 'rrwh_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'rrwh_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'rrwh_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'rrwh_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'rrwh_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'rrwh_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'rrwh_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'rrwh_rooftopArea', label: 'Landscape Area', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_cementTilesRoof', label: 'Cement/ Tiles Roof', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_roofGardenThin', label: 'Roof Garden (<100 thick)', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_roofGardenMedium', label: 'Roof Garden (100 to 200mm thick)', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_roofGardenThick', label: 'Roof Garden (201 to 500mm thick)', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_roofGardenHeavy', label: 'Roof Garden (>500mm thick)', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_daysServiced', label: 'Days Serviced', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_annualRechargedWater', label: 'Water Recharged Annually', unit: 'KL', type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'r' },
    { id: 'rrwh_rpSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rpNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rpRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rtSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rtNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rtRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rtbwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rtbwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rtbwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rsbwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rsbwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rsbwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rbwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rbwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh_rbwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'rrwh__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'rrwh__pi_boreWellDepth', label: 'Borewell Depth', unit: 'm', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'rrwh__pi_boreWellCapex', label: 'Borewell CAPEX', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'rrwh__pi_noOfAutonomyDays', label: 'No. of Autonomy Days', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'rrwh__pi_demandOption', label: 'Water Demand', unit: null, type: 'string[]', tags: [], rw: 'w' },
    { id: 'rrwh__pi_maximiseOption', label: 'Maximise Options', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'rrwh__pi_rechargeStructureOptions', label: 'Recharge Structures', unit: null, type: 'string[]', tags: [], rw: 'w' },
    { id: 'rrwh__pi_rpRechargePercentage', label: 'Recharge Pits', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'rrwh__pi_rtRechargePercentage', label: 'Recharge Trenches', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'rrwh__pi_rwRechargePercentage', label: 'Recharge Wells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'rrwh__pi_rtbwRechargePercentage', label: 'Recharge Shafts with Borewells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'rrwh__pi_rsbwRechargePercentage', label: 'Recharge Trenches with Borewells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'rrwh__pi_rbwRechargePercentage', label: 'Recharge Existing Borewells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'rrwh__pi_tankSize', label: 'Tank Size', unit: 'KL', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'rrwh__pi_topSoilPermeability', label: 'Top Soil Permeability', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'rrwh__pi_subsurfaceSoilPermeability', label: 'Subsurface Soil Permeability', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  srwh: [
    { id: 'srwh_savings', label: 'Savings(Annual)', unit: 'KL', type: 'number', tags: ['result-resource', 'Resource', 'Intervention'], rw: 'r' },
    {
      id: 'srwh_savingsConverted',
      label: 'Avoided Annually',
      unit: 'Water Tanker',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention'],
      rw: 'r',
    },
    { id: 'srwh_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention'], rw: 'r' },
    { id: 'srwh_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention'], rw: 'r' },
    { id: 'srwh_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention'], rw: 'r' },
    { id: 'srwh_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention'], rw: 'r' },
    { id: 'srwh_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'srwh_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial'], rw: 'r' },
    { id: 'srwh_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention'], rw: 'r' },
    { id: 'srwh_totalCapexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['_TotalCapexConverted', 'Intervention'], rw: 'r' },
    { id: 'srwh_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'srwh_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'srwh_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'srwh_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report'], rw: 'r' },
    { id: 'srwh_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'srwh_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'srwh_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'srwh_landscapeArea', label: 'Landscape Area', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_concretePavement', label: 'Concrete Pavement', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_gravelPavement', label: 'Gravel Pavement', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_openGridConcretePavement', label: 'Open Grid Concrete Pavement', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_flatSurface', label: 'Flat Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_vegetationFlatSurface', label: 'Vegetation Flat Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_vegetationOpenGridGrassPavement', label: 'Vegetation Open Grid Grass Pavement', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_vegetationSlightlySlopedSurface', label: 'Vegetation Slightly Sloped Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_vegetationHillySurface', label: 'Vegetation Hilly Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_vegetationSteepSurface', label: 'Vegetation Steep Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_grassSlightlySlopedSurface', label: 'Grass Slightly Sloped Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_grassHillySurface', label: 'Grass Hilly Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_grassSteepSurface', label: 'Grass Steep Surface', unit: 'Sqm', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_daysServiced', label: 'Days Serviced', unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_annualRechargedWater', label: 'Water Recharged Annually', unit: 'KL', type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rpSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rpNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rpRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rtSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rtNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rtRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rtbwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rtbwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rtbwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rsbwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rsbwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rsbwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rbwSurfaceArea', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rbwNumberOfStructures', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh_rbwRechargeCapex', label: null, unit: null, type: 'number', tags: [], rw: 'r' },
    { id: 'srwh__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'srwh__pi_boreWellDepth', label: 'Borewell Depth', unit: 'm', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'srwh__pi_boreWellCapex', label: 'Borewell CAPEX', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'srwh__pi_demandOption', label: 'Water Demand', unit: null, type: 'string[]', tags: [], rw: 'w' },
    { id: 'srwh__pi_maximiseOption', label: 'Maximise Options', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'srwh__pi_rechargeStructureOptions', label: 'Recharge Structures', unit: null, type: 'string[]', tags: [], rw: 'w' },
    { id: 'srwh__pi_rpRechargePercentage', label: 'Recharge Pits', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'srwh__pi_rtRechargePercentage', label: 'Recharge Trenches', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'srwh__pi_rwRechargePercentage', label: 'Recharge Wells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'srwh__pi_rtbwRechargePercentage', label: 'Recharge Shafts with Borewells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'srwh__pi_rsbwRechargePercentage', label: 'Recharge Trenches with Borewells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'srwh__pi_rbwRechargePercentage', label: 'Recharge Existing Borewells', unit: '%', type: 'number', tags: [], rw: 'w' },
    { id: 'srwh__pi_tankSize', label: 'Tank Size', unit: 'KL', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'srwh__pi_topSoilPermeability', label: 'Top Soil Permeability', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'srwh__pi_subsurfaceSoilPermeability', label: 'Subsurface Soil Permeability', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'srwh__pi_noOfAutonomyDays', label: 'No. of Autonomy Days', unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
  ],
  sc: [
    { id: 'sc_capex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['result-financial', 'Capex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'sc_savings', label: 'Savings(Annual)', unit: 'kWh', type: 'number', tags: ['result-resource', 'Resource', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'sc_savingsConverted',
      label: 'Powered Annually',
      unit: 'Homes',
      type: 'number',
      tags: ['result-resource', 'ResourceConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'sc_opex', label: 'OPEX Savings', unit: 'Rs', type: 'number', tags: ['result-financial', 'Opex', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'sc_capexConverted', label: 'CAPEX Increase', unit: 'Rs/Sqft', type: 'number', tags: ['CapexConverted', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'sc_totalCapex', label: 'CAPEX Increase', unit: 'Rs', type: 'number', tags: ['_TotalCapex', 'Intervention', 'uiRound:0'], rw: 'r' },
    {
      id: 'sc_totalCapexConverted',
      label: 'CAPEX Increase',
      unit: 'Rs/Sqft',
      type: 'number',
      tags: ['_TotalCapexConverted', 'Intervention', 'uiRound:0'],
      rw: 'r',
    },
    { id: 'sc_carbon', label: 'Carbon Mitigated', unit: 'tCO2e', type: 'number', tags: ['result-resource', 'Carbon', 'Intervention', 'uiRound:0'], rw: 'r' },
    { id: 'sc_irr', label: 'IRR', unit: '%', type: 'number', tags: ['result-financial', 'uiRound:0'], rw: 'r' },
    { id: 'sc_paybackPeriod', label: 'Payback Period', unit: 'Years', type: 'number', tags: ['result-financial', 'uiRound:1'], rw: 'r' },
    { id: 'sc_sdgNumber', label: 'SDG Number', unit: null, type: 'number[]', tags: ['result-resource'], rw: 'r' },
    { id: 'sc_sdgText', label: 'SDG Text', unit: null, type: 'string', tags: ['SdgText'], rw: 'r' },
    { id: 'sc_capexPostSdPlus', label: 'Capex Post SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'sc_capexPreSdPlus', label: 'Capex Pre SD Plus', unit: 'Rs', type: 'number', tags: ['Report', 'uiRound:0'], rw: 'r' },
    { id: 'sc_builtUpArea', label: null, unit: 'nos', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'sc_typeOfConstruction', label: null, unit: null, type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'sc_slabThickness', label: null, unit: 'm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'sc_beamDepth', label: null, unit: 'mm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'sc_wallThickness', label: 'Rooftop Area', unit: 'mm', type: 'number', tags: ['project-input'], rw: 'r' },
    { id: 'sc_donuts', label: null, unit: null, type: 'Donuts', tags: [], rw: 'r' },
    { id: 'sc_overview', label: null, unit: null, type: 'Overview', tags: [], rw: 'r' },
    { id: 'sc_passiveImpact', label: null, unit: null, type: 'PassiveImpact', tags: ['uiRound:0'], rw: 'r' },
    { id: 'sc_vendorCapex', label: 'Selected Vendor', unit: 'Rs', type: 'number', tags: [], rw: 'r' },
    { id: 'sc__pi_subStructureggbsPercentage', label: 'Sub Structure', unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_superStructureggbsPercentage', label: 'Super Structure', unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_verticaleggbsPercentage', label: 'Vertical', unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_subStructureFlyAsh', label: 'Sub Structure', unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_superStructureFlyAsh', label: 'Super Structure', unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_verticalFlyAsh', label: 'Vertical', unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_mineralAdmixture', label: 'Mineral Admixture', unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'sc__pi_customCapex', label: 'Custom', unit: 'Rs', type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_subStructureQuantity', label: 'Sub Structure Quantity', unit: 'cum', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_superStructureQuantity', label: 'Super Structure Quantity', unit: 'cum', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_afSubStructureQuantity', label: 'Sub Structure Quantity', unit: 'cum', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_afSuperStructureQuantity', label: 'Super Structure Quantity', unit: 'cum', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_afVerticalQuantity', label: 'Vertical Quantity', unit: 'cum', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_useropcCapex', label: 'OPC CAPEX per Bag', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_userggbsPercentage', label: 'Base Case GGBS %', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_useropcEmboidedCarbon', label: 'OPC Carbon Emission', unit: 'Rs', type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_capexSourceMultiplier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'sc__pi_product', label: null, unit: null, type: 'UserActualDefault', tags: [], rw: 'w' },
    { id: 'sc__pi_selectedProductIdentifier', label: null, unit: null, type: 'string', tags: [], rw: 'w' },
    { id: 'sc__pi_rccSubStructureQuantity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_rccSuperStructureQuantity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_userafSubStructureQuantity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_userafSuperStructureQuantity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_userafVerticalQuantity', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_opcCapex', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_baseCaseggbsPercentage', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
    { id: 'sc__pi_opcEmboidedCarbon', label: null, unit: null, type: 'number', tags: [], rw: 'w' },
  ],
};
